html{
    scroll-behavior: smooth;
}
.brand{
    font-size: x-large !important;
    color:white !important;
    font-family: 'Noto Serif', sans-sherif !important;
    font-weight: 900;
}
.brandActive{
    font-size: x-large !important;
    color: crimson !important;
    font-family: 'Noto Serif', sans-sherif !important;
    font-weight: 900;
}
.active{
    background-color: white;
}
.menuItem{
    color: #ffffff !important;
    font-size: 12px !important;
    opacity: 1;
    font-weight: 700 !important;
    letter-spacing: 1px;
    /* padding-right: 20px;
    padding-left: 20px;
    height: 50px !important; */
    transition: all 0.3s ease-in-out;
    margin-left: 5px;
    display: flex;
    align-items: center;
    border-bottom: 0px solid #900C3F;
    background: transparent;
}
.menuItemActive{
    color: crimson !important;
    font-size: 12px !important;
    opacity: 1;
    font-weight: 700 !important;
    letter-spacing: 1px;
    transition: all 0.3s ease-in-out;
    margin-left: 5px;
    display: flex;
    align-items: center;
    border-bottom: 0px solid #333;
    background: transparent;
}
@media screen and (min-width: 601px){ 
    .menuItem:hover{
        /* background: #900C3F;
        color: white !important;
        font-weight: 600 !important;
        opacity: 1 !important;
        transition: all 0.3s ease-in-out; */
        /* text-decoration: underline; */
        border-bottom: 2px solid #900C3F;
        padding: 2px;
        transition: all 0.3s ease-in;
    }
    /* .menuItem:focus{
        background-color: #900C3F;
        color: white !important;
        font-weight: 600 !important;
        opacity: 1 !important;
        transition: all 0.3s ease-in-out;
    } */
    .navbar-light .navbar-toggler-icon {
        background-color: #ffffff;
    }
    .navbar-toggler{
        color: #900C3F;
        border: none;
        border-color: transparent;
    }
}