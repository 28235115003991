/*Common*/
html{
  scroll-behavior: smooth;
}
body {
  background: #f9f9f9;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  position: relative;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}
.bold {
  font-weight: bold;
  color: #f30a49;
}

h1, h2, h3, h4 {
  font-weight: bold;
}

#home {
  background: url('../../images/new-bg.jpg') no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

/*For Desktop browser, iPad, Tablet Screen*/
@media screen and (min-width: 601px) {
  .heading {
    font-size: 50px;
    font-weight: 700;
  }
  .subheading {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 60px;
  }
  
  p {
    letter-spacing: 0px;
    line-height: 28px;
  }
  
  #home .topimgmargin{
    margin-top: 35px;
  }
  #home .profileImage{
      width: 170px;
      height: 170px;
      margin-bottom: 17px; 
      -webkit-border-radius: 99em;
      -moz-border-radius: 99em;
      border-radius: 99em;
      border: 5px solid #fff;
      box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
      background:rgba(203, 204, 206, 0.9);
  }
  #home hr {
    border: 2px solid #fff;
    width: 100px;
    margin: auto;
  }
  
  #home h3 {
    letter-spacing: 2px;
    font-size: 18px;
  }
  .icon {
    position: relative;
    z-index: 1;
    font-size: 2.7rem;
    color: #ffffff;
    line-height: 2.4;
    padding: 5pt;
  }
  .icon:hover{
    color: crimson;
    cursor: pointer;
  }
  #home .btn {
    background: crimson;
    border-radius: 0;
    border: 3px solid transparent;
    color: #ffffff;
    font-weight: bold;
    padding: 16px 42px;
    margin-top: 40px;  
    text-align: center;
    transition: all 0.4s ease-in-out;

  }
  #home .btn:hover {
     background: #ffffff; 
     color: #333;
  }
}
@media screen and (max-width: 600px){ 
  #home {
    background: url('../../images/mini-mobile-bg.jpg') no-repeat;
  }
  .heading {
    font-size: 40px;
    font-weight: 600;
  }
  .subheading {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 60px;
  }
  #home .topimgmargin{
    margin-top: 1px;
  }
  #home .profileImage{
    width: 150px;
    height: 150px;
    margin-bottom: 17px; 
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 5px solid #fff;
    box-shadow: 0 3px 2px rgba(255, 255, 255, 0.2);
    background:rgba(35, 35, 35, 1);
}
  #home hr {
    border: 2px solid #fff;
    width: 100px;
    margin: auto;
  }
  #home h3 {
    letter-spacing: 1.2px;
    font-size: 15px;
  }
  .icon {
    position: relative;
    z-index: 1;
    font-size: 2.7rem;
    color: #fff;
    line-height: 2.4;
    padding: 5pt;
  }
  #home .btn {
    background: crimson;
    border-radius: 0;
    border: transparent;
    color: #fff;
    font-weight: bold;
    padding: 16px 42px;
    margin-top: 40px;
    transition: all 0.4s ease-in-out;
  }
  #home .btn:hover {
    background: #fff;
    color: #333;
  }
}