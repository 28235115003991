
#skills {
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    padding-top: 15px; 
    /* padding-bottom: 15px; */
    /* background-color: #333; */
    font-family: 'Roboto', sans-serif;
}

#skills .card{
    padding: 40px;
    background: #fff;
    border-radius: 7px;
    margin: 60px auto;
}
#skills .shadow1 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}
#skills h2{
    color: #333;
    padding-right: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid crimson;
    text-align: right;
}
#skills .siiconsize{
    font-size: 50px !important;
    color: crimson;
    opacity: 0.8;
    margin:auto;
}
@media screen and (min-width: 601px) {
    #skills h4{
        font-size: 18px !important;
        font-weight: 600;
    }
    #skills .skilldisplay{
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    #skills h4{
        font-size: 18px !important;
        font-weight: 600;
    }
    #skills .skilldisplay{
        font-size: 14px;
    }
}