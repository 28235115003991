#work {
    padding-top: 15px;
    /* background-color: #333; */
    font-family: 'Roboto', sans-serif;
}
#work .card{
    padding: 40px;
    background: #fff;
    border-radius: 7px;
    margin: 60px auto;
}
#work .shadow1 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}
#work h2{
    color: #333;
    padding-right: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid crimson;
    text-align: right;
}
#work .work-box {
    margin-bottom: 3rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color:#f7f7f7;
}
#work .work-box:hover img {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}
#work .work-box-shadow{
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}
  
#work .work-img {
    display: block;
    overflow: hidden;
}
#work h3{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #333;
}
.work-img img {
    transition: all 1s;
  }
  
  .work-content {
    padding: 2rem 3% 1rem 4%;
  }
  
  .work-content .w-more {
    color: #4e4e4e;
    font-size: .8rem;
  }
  
  .work-content .w-more .w-ctegory {
    color: #333;
  }
  
  .work-content .w-like {
    font-size: 2.5rem;
    color: #333;
    float: right;
  }
  
  .work-content .w-like a {
    color: #333;
  }
  
  .work-content .w-like .num-like {
    font-size: .7rem;
  }
  
  .w-title {
    font-size: 1.2rem;
  }