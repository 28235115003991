body{
    /* background: #f9f9f9; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
#experience{
     padding-top: 15px;
     /* background-color: #333; */
    /* padding-bottom: 90px; */
}
#experience .card{
    padding: 40px;
    background: #fff;
    border-radius: 7px;
    margin: 60px auto;
}
#experience .shadow1 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}
#experience h2{
    color: #333;
    padding-left: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid crimson;
    text-align: left;
}




#experience h1{
    color: #212529;
    text-align: center;
}
#experience h3{
    color: #212529;
    font-size: 20px;
    font-weight: 600;
}
#experience h4{
    color: #212529;
    font-size: 15px;
    font-weight: 500;
}
#experience h5{
    color: #212529;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
}
#experience .elementclassname{
    color: #333;
    font-size: medium;
}
#experience .custom-line:before {
    background: #3A3A3A;
}



