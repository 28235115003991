body{
    font-family: 'Montserrat', sans-serif;
    /* font-weight: 400; */
}
#contact{
    /* background: #f9f9f9; */
    padding-top: 15px;
    /* background-color: #333; */
    margin-bottom: 20px;
}

#contact .card{
    padding: 40px;
    background: #fff;
    border-radius: 7px;
    margin: 60px auto;
}
#contact .shadow1 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}
#contact h2{
    color: #333;
    padding-left: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid crimson;
    text-align: left;
}
#contact .mobileicon{
    font-size: 40px !important;
    color: #333;
    font-weight: 600 !important;
}
#contact .mailIcon{
    font-size: 40px !important;
    color: #437EDE;
    font-weight: 600 !important;
}
#contact .locationIcon{
    font-size: 40px !important;
    color: red;
    font-weight: 600 !important;
}



#contact h1{
    color: #212529;
    text-align: center;
}
/* #contact h2{
    color: #212529;
    text-align: center;
    word-spacing: 3px;
} */
#contact h6{
    color: #212529;
    font-weight: 400;
    text-align: center;
    word-spacing: 1px;
}

#contact .input{
    width: 100%;  
    height: 45px;
}
#contact .inputHeight{
    height: 50px;
}
#contact .sendemail{
    width: 100%;
    height: 60px;
    margin-right: auto;
    margin-left: auto;
    font-size: 20px;
    font-weight: bold;
    background: crimson;
    /* border-radius: 0; */
    /* border: 3px solid #404040; */
    color: #fff;
    /* opacity: 0.8; */
    padding: 10px;
    transition: all 0.4s ease-in-out;
}
#contact .sendemail:hover {
    background: #fff;
    color: #333;
    border: 2px solid crimson;
  }
#contact .message{
    display: flex;
    justify-content: right;
}
@media screen and (min-width: 601px){
    /* #contact .sendemail{
        width: 100%;
    } */
}
@media screen and (max-width: 600px){ 
    /* #contact .sendemail{
        width: 100%;
    } */
}
