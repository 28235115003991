.modalTitle{
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    font-weight: 600;
    color: #333;
}
p{
    font-family: 'Noto', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #000;
}
.anchorTag{
    text-decoration: underline;
    color: crimson;
}
.closeButton{
    background-color: crimson;
    color: #fff;
    font-weight: 600;
}
