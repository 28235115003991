html{
    scroll-behavior: smooth;
}
body{
    font-family: 'Montserrat', sans-serif;
}
#about {
     padding-top: 15px; 
     /* padding-bottom: 15px; */
     /* background-color: #333; */
	/* padding-bottom: 90px; */
}
#about .card{
    padding: 40px;
    background: #fff;
    border-radius: 7px;
    margin: 60px auto;
}
#about .shadow1 { 
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}
#about h2{
    color: #333;
    padding-left: 25px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    border-bottom: 2px solid crimson;
}


#about h1{
    color: #212529;
    text-align: center;
}
/* #about .colalign{
    display: inline-block;
    align-content: center;
}
#about .colbackground {
    background: #900c3f;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
#about .fasize {
    font-size: 35px !important;
    color: white;
    margin:auto;
}
#about h3{
    text-align: center;
    color: #333;
    font-weight: 600;
}
#about p{
    color: #333;
    font-weight: 500;
    font-size: medium;
}
@media screen and (min-width: 601px) {
    #about .skillsHeading{
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 3px;
        opacity: 1;
    }
    #about .siiconsize{
        font-size: 50px !important;
        color: #900c3f;
        opacity: 0.8;
        margin:auto;

    }
    #about h4{
        font-size: 18px !important;
        font-weight: 600;

    }
    #about h5{
        color: #333;
        opacity: 0.8;
        font-weight: 500;
    }
    #about .skilldisplay{
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    #about .skillsHeading{
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 3px;
    }
    #about .siiconsize{
        font-size: 50px !important;
        color: #900c3f;
        opacity: 0.8;
        margin:auto;
    }
    #about h4{
        font-size: 18px !important;
        font-weight: 600;

    }
    #about h5{
        color: #333;
        opacity: 0.8;
        font-weight: 500;
    }
    #about .skilldisplay{
        font-size: 14px;
    }
} */